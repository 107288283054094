.ContentContainer {
    width: 90%;
    margin: 0 auto;
}

.Content {
    padding: 0 5%;
    margin-top: 1%;
}

.Content h1 {
    background-color: navy;
    color: whitesmoke;
    text-align: center;
    padding: 5px 0;
    margin-bottom: 0;
}

.Content h3 {
    text-align: center;
    border: 1px solid navy;
    padding: 10px;
    margin-top: 0;
}

.Offerings {
    background-color: lightblue;
    border: 2px solid navy;
    text-align: center;
    padding: 32px;
}

.Offerings td {
    width: 50%;
    padding: 8px 0;
}

.Carousel {
    display: flex;
    overflow: hidden;
    border: 1px solid navy;
    padding: 0;
}

.CarouselSlider {
    position: relative;
    display: flex;
    padding: 0;
}

.CarouselSlide {
    list-style: none;
    padding: 0;
}

.CarouselSlide img {
    max-width: 100vw;
    height: auto;
}

.CarouselButtonLeft {
    position: absolute;
    top: 30%;
    left: 5%;
    z-index: 2;
}

.CarouselButtonRight {
    position: absolute;
    top: 30%;
    right: 5%;
    z-index: 2;
}

@media only screen and (max-width: 1024px) {
    .ContentContainer {
        width: 100%;
    }

    .Content {
        padding: 0;
    }

    .Offerings {
        padding: 4px;
    }

    .Offerings td {
        padding: 4px;
        border: 1px solid navy;
    }
}