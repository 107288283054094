.NavButton {
    display: inline;
    cursor: pointer;
}

.NavButton a {
    color: lightblue;
    margin: 0.25%;
    padding: 0.5%;
    font-size: 18px;
}

.NavButton a:hover,
.NavButton a:active,
.NavButton a.active {
    color: navy;
    background-color: lightyellow;
    border: 2px solid lightblue;
}

@media only screen and (max-width: 1185px) {
    .NavButton a {
        display: block;
        padding: 10px 10px;
        margin: 1px;
        color: blue;
        background-color: lightblue;
        border: 2px solid lightyellow;
        border-radius: 10px;
        text-decoration: none;
    }
}