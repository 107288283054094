.SiteFooter {
    background-color: navy;
    width: 100%;
    height: 16px;
    margin-top: 30%;
    padding: 10px 0;
    font-size: 12px;
}

.Links {
    margin-left: 5%;
}

.Links a {
    color: whitesmoke;
    text-decoration: none;
    margin-right: 5%;
}

.Links a:hover,
.Links a:active {
    color: lightyellow;
}

.Copyright {
    color: whitesmoke;
    position: absolute;
    right: 5%;
}

@media only screen and (max-width: 1024px) {
    .SiteFooter {
        padding: 2px 0;
        height: 200px;
    }

    .Links a {
        display: block;
        padding: 5px 0;
        margin-left: 5%;
    }
}