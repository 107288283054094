.companyHeader {
    width: 100%;
    height: 24px;
    background-color: navy;
    padding: 1% 0;
    margin-bottom: 8px;
}

.companyName {
    color: whitesmoke;
    font-size: 2em;
}

.desktopLinkContainer {
    display: inline;
    width: 100%;
    margin-left: 2%;
}

.authContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    color: whitesmoke;
}

.authLink {
    color: whitesmoke;
    padding: 0 4px;
}

.authLink:hover {
    color: lightyellow;
}

.authLinkActive {
    color: lightyellow;
}

.mobileLinkContainer {
    width: 100%;
    margin-top: 36px;
    background-color: navy;
    position: relative;
    z-index: 1;
}

.MobileLinkSeparator {
    color: whitesmoke;
    font-weight: bold;
    font-size: 16px;
    padding: 16px;
}

.menuButtonContainer {
    font-size: 32px;
    color: whitesmoke;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    visibility: hidden;
}

.menuButton {
    display: inline-block;
}

.menuLine {
    height: 2px;
    width: 20px;
    margin: 4px 0;
    background-color: whitesmoke;
}

.isVisible {
    display: block;
}

.notVisible {
    display: none;
}

.companyLinkContainer {
    position: relative;
}

.companyContainerToggleButton {
    display: inline;
    cursor: pointer;
    color: blue;
    background-color: lightblue;
    margin: 0.25%;
    padding: 0.5%;
    font-size: calc(18px + 0.5vw);
    border: 2px solid lightyellow;
    border-radius: 10px;
}

.companyContainerToggleButton:hover {
    color: navy;
    background-color: lightyellow;
    border: 2px solid lightblue;
}

.companyLink {
    display: block;
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 500px;
    margin: 25px 0;
    z-index: 1;
}

.companyLink a {
    color: blue;
    background-color: lightblue;
    margin: 0.25%;
    padding: 2%;
    font-size: calc(18px + 0.5vw);
    border: 2px solid lightyellow;
    border-radius: 10px;
    text-decoration: none;
}

.companyLink a:hover,
.companyLink a:active,
.companyLink a.active {
    color: navy;
    background-color: lightyellow;
    border: 2px solid lightblue;
}

@media only screen and (max-width: 1315px) {
    .companyHeader {
        height: 36px;
    }

    .companyName {
        position: absolute;
        top: 10px;
        left: 5px;
    }

    .desktopLinkContainer {
        display: none;
    }

    .menuButtonContainer {
        visibility: visible;
    }

    .companyLinkContainer {
        display: block;
        padding: 10px 10px;
        margin: 1px;
    }

    .companyContainerToggleButton {
        display: block;
        padding: 10px 10px;
        margin: 1px;
    }
}