.siteHeader {
    width: 100%;
    height: 48px;
    background-color: navy;
    padding: 1% 0;
}

.companyName {
    color: whitesmoke;
    font-size: 36px;
}

.desktopLinkContainer {
    display: inline;
    width: 100%;
    margin-left: 2%;
}

.authContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    color: whitesmoke;
}

.authLink {
    color: whitesmoke;
    margin: 0 16px;
}

.authLink:hover {
    color: lightyellow;
}

.authLinkActive {
    color: lightyellow;
}

.mobileLinkContainer {
    width: 100%;
    margin-top: 50px;
    background-color: navy;
    position: relative;
    z-index: 1;
}

.MobileLinkSeparator {
    color: whitesmoke;
    font-weight: bold;
    font-size: 16px;
    padding: 16px;
}

.menuButtonContainer {
    font-size: 32px;
    color: whitesmoke;
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
    visibility: hidden;
}

.menuButton {
    display: inline-block;
}

.menuLine {
    height: 2px;
    width: 20px;
    margin: 4px 0;
    background-color: whitesmoke;
}

.isVisible {
    display: block;
}

.notVisible {
    display: none;
}

.companyLinkContainer {
    position: relative;
}

.companyContainerToggleButton {
    display: inline;
    cursor: pointer;
    text-decoration: underline;
    color: lightblue;
    margin: 0.25%;
    padding: 0.5%;
    font-size: calc(18px);
}

.companyContainerToggleButton:hover {
    color: navy;
    background-color: lightyellow;
    border: 2px solid lightblue;
}

.companyLink {
    background-color: navy;
    display: block;
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 500px;
    padding: 25px 4px;
    z-index: 1;
}

.companyLink a {
    color: lightblue;
    margin: 0.25%;
    padding: 0.5%;
    font-size: calc(18px);
}

.companyLink a:hover,
.companyLink a:active,
.companyLink a.active {
    color: navy;
    background-color: lightyellow;
    border: 2px solid lightblue;
}

@media only screen and (max-width: 1185px) {
    .siteHeader {
        height: 60px;
    }

    .companyName {
        position: absolute;
        top: 10px;
        left: 5px;
    }

    .desktopLinkContainer {
        display: none;
    }

    .menuButtonContainer {
        visibility: visible;
    }

    .companyLinkContainer {
        display: block;
        padding: 10px 10px;
        margin: 1px;
    }

    .companyContainerToggleButton {
        display: block;
        padding: 10px 10px;
        margin: 1px;
    }
}